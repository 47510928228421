import { React, useState } from "react"
import {
    Dialog,
    DialogTitle,
    AppBar,
    IconButton,
    Toolbar,
    Typography
} from "@mui/material"
import { Grid, Button } from "@material-ui/core"
import CloseIcon from "@mui/icons-material/Close"

import useStyles from "../styles/FaqStyles"
import TVNoSignalFAQ from "../faq/TVNoSignalFAQ"
import TVScreenBeamFAQ from "../faq/TVScreenBeamFAQ"
import TVWirelessCastFAQ from "../faq/TVWirelessCastFAQ"
import TVWirelessAppFAQ from "../faq/TVWirelessAppFAQ"
import TVWirelessBuffer from "../faq/TVWirelessBuffer"
import ScreenBeamWirelessCastFAQ from "../faq/ScreenBeamWirelessCastFAQ"
import ScreenBeamPowerFAQ from "../faq/ScreenBeamPowerFAQ"
import ScreenBeamNoConnectionFAQ from "../faq/ScreenBeamNoConnectionFAQ"
import {
    hdmiTraditional,
    computerOnK2Training,
    computerOnMarcy,
    computerOnPikes,
    hdmiK2,
    inputK2,
    hdmi2,
    inputHdmi2,
    hdmiTraining,
    inputTraining
} from "../faq/FAQConfigConstants"

function FAQDialogWrapper({ chosen, clickedObj }) {
    const classes = useStyles()

    // key-value pair map designating the respective FAQ components for each category
    const categoryMap = {
        "TV": [
            <TVNoSignalFAQ />,
            <TVScreenBeamFAQ />,
            <TVWirelessCastFAQ />,
            <TVWirelessAppFAQ />,
            <TVWirelessBuffer />,
            <ScreenBeamWirelessCastFAQ />,
            <ScreenBeamPowerFAQ />,
            <ScreenBeamNoConnectionFAQ />
        ],
        "Phone/Audio": [],
        "Internet": [],
        "Camera": [],
        "Computer": [],
        "Other": []
    }

    // key-value pair map designating the respective FAQ components for each building
    const locationMap = {
        "Ben Nevis": [
            <TVNoSignalFAQ
                key={1}
                computerStep={"Ensure the HDMI cable is plugged into your laptop or, if you are wirelessly casting, the conference room computer located underneath the TV has it's HDMI cable is plugged into the TV."}
                hdmiStep={hdmi2}
                inputStep={inputHdmi2}
                confRoomComp={'Ensure the TV and, if you are wirelessly casting, the conference room computer are turned on.'}
            />,
            <TVWirelessCastFAQ
                key={2}
                connectName={"BenNevisConfrenceRoom"}
                PGIGuest={true}
            />,
            <TVWirelessBuffer 
                key={3} 
                noWifi={true}
                connectName={"BenNevisConfrenceRoom"}
            />
        ],
        "Everest": [
            <TVNoSignalFAQ
                key={1}
                confRoomComp={"Ensure the TV is on. If it isn't, locate the controller or hit the power button on the TV."}
                screenBeam={true}
            />,
            <TVWirelessCastFAQ
                key={2}
                screenBeam={true}
                connectName={"EVEREST Board Room"}
            />,
            <TVScreenBeamFAQ 
                key={3}
                connectName={"EVEREST Board Room"}
                everest={true}
             />,
             <TVWirelessBuffer 
                key={4} 
                connectName={"EVEREST Board Room"}
                screenBeam={true}
            />
        ],
        "K2": [
            <TVNoSignalFAQ
                key={1}
                confRoomComp={"Ensure the TV is on. If it isn't, locate the controller or hit the power button on the TV."}
                screenBeam={true}
            />,
            <TVWirelessCastFAQ
                key={2}
                screenBeam={true}
                connectName={"K2 Conference Room"}
            />,
            <TVScreenBeamFAQ 
                key={3}
                connectName={"K2 Conference Room"}
                K2={true}
            />,
            <TVWirelessBuffer 
                key={4} 
                connectName={"K2 Conference Room"}
                screenBeam={true}
            />
        ],
        "The Excellence Room": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnMarcy}
                confRoomComp={"Ensure the TV is turned on. If it isn't, locate the controller and hit the power button or hit the power button on the TV."}
            />
        ],
        "Mount Marcy": [
            <TVNoSignalFAQ 
                key={1} 
                computerStep={computerOnMarcy} 
                confRoomComp={"Ensure the TV is turned on. If it isn't, locate the controller and hit the power button or hit the power button on the TV."}
                hdmiStep={<>Ensure the HDMI cable is conncted to the HMDI port in the <u>wall directly under the TV</u>.</>}
            />
        ],
        "Matterhorn": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnMarcy}
                hdmiStep={hdmi2}
                inputStep={inputHdmi2}
                matterhorn={true}
                confRoomComp={"Ensure the TV is on. If it isn't, locate the controller or hit the power button on the TV."}
            />
        ],
        "Mont Blanc": [
            <TVNoSignalFAQ
                key={1}
                confRoomComp={<>Ensure the TV is turned on. If it isn't, locate the controller and hit the power button or hit the power button on the <u>bottom left of the TV</u>.</>}
                computerStep={computerOnMarcy}
            />
        ],
        "Pikes Peak": [
            <TVNoSignalFAQ
                key={1}
                computerStep={computerOnMarcy}
                hdmiStep={hdmi2}
                inputStep={inputHdmi2}
                matterhorn={true}
                confRoomComp={"Ensure the TV is on. If it isn't, locate the controller or hit the power button on the TV."}
            />
        ],
        "Training Room": [
            <TVNoSignalFAQ
                key={1}
                confRoomComp={"Ensure the TVs are on. If they aren't, locate the controllers or hit the power button on the TVs."}
                screenBeam={true}
            />,
            <TVWirelessCastFAQ 
                key={2} 
                screenBeam={true}
                connectName={"170 - Training Room"} 
            />,
            <TVScreenBeamFAQ 
                key={3}
                connectName={"170 - Training Room"}
             />,
             <TVWirelessBuffer 
                key={4} 
                connectName={"170 - Training Room"}
                screenBeam={true}
            />
        ],
        "Chasseral": []
    }

    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    // obtain list of FAQ components that satisfy both filters (location + category)
    const getIntersectionFAQ = (chosen) => {
        let locationArray = locationMap[chosen.location]
        let categoryArray = categoryMap[chosen.category]

        // get intersection of 2 arrays
        const intersect = locationArray.filter((locationComponent) =>
            categoryArray.some(
                (categoryComponent) =>
                    categoryComponent.type.name === locationComponent.type.name
            )
        )
        return intersect
    }

    return (
        <div>
            <Grid item xs={6} sm={8} align="center">
                <Button
                    size="small"
                    variant="contained"
                    className={classes.FAQButton}
                    onClick={handleClickOpen}
                >
                    common solutions for {chosen.category}
                </Button>
            </Grid>
            <Dialog fullScreen open={open} onClose={handleClose}>
                <DialogTitle sx={{ marginBottom: "30px" }}>
                    <AppBar sx={{ backgroundColor: "rgb(177, 177, 179)" }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography
                                variant="h5"
                                sx={{ paddingLeft: "5px" }}
                            >
                                Common Solutions
                            </Typography>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>
                {clickedObj[chosen.category] &&
                    getIntersectionFAQ(chosen).map(
                        (faqComponent) => faqComponent
                    )}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Button
                        key={0}
                        size="small"
                        variant="contained"
                        className={classes.BackButton}
                        onClick={handleClose}
                    >
                        BACK
                    </Button>
                </div>
            </Dialog>
        </div>
    )
}

export default FAQDialogWrapper
