import React from "react"
import { Container, Typography, Grid } from "@material-ui/core"

import useStyles from "./styles/FooterStyles"

/* Component that represents the footer display on interface. */
function Footer() {
    // styles classes
    const classes = useStyles()

    return (
        <div className={classes.Footer}>
            <Container>
                <Grid container spacing={0} className={classes.FooterItems}>
                    <Grid item>
                        <Typography variant="h6">
                            Precipart Global Digital Solutions
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" className={classes.Version}>
                            v1.2.0
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Footer
