import React from "react"

// traditional cast with HDMI cord
const hdmiTraditional = (
    <>Connect the other end of the HDMI cable to your laptop.</>
)

// step for "No signal on TV" problem, for conference rooms with computers connected to TV

const computerOnK2Training = (
    <>
        Ensure the PC attached to the back of the <i>left</i> TV is on.
    </>
)
const computerOnMarcy = <>Ensure the HDMI cable is plugged into your laptop.</>
const computerOnPikes = (
    <>
        Ensure the PC located under the counter, in the <u>cabinet</u>, is on.
    </>
)

// HDMI connection step for "No signal on TV", for K2 conf room (2 televisions)
const hdmiK2 = (
    <>
        For the <i>left</i> TV, ensure the HDMI cable is connected to the{" "}
        <b>HDMI2 port</b> on its side. <br /> For the <i>right</i> TV, ensure
        the HDMI cable is connected to the <b>HDMI1 port</b> on its side.
    </>
)
const inputK2 = (
    <>
        Ensure the TV <b>input</b> is set to <b>HDMI2</b> for the <i>left</i>{" "}
        TV, and <b>HDMI1</b> for the <i>right</i> TV.
    </>
)

// HDMI connection step for "No signal on TV", for conf room with HDMI2 connection.
const hdmi2 = (
    <>
        Ensure the HDMI cable is connected to the <b>HDMI2 port</b> on the side
        of the TV.
    </>
)
const inputHdmi2 = (
    <>
        Ensure the TV <b>input</b> is set to <b>HDMI2</b>.
    </>
)

// HDMI connection step for "No signal on TV", for Training Room conf room (2 televisions)
const hdmiTraining = (
    <>
        Ensure the HDMI cable is connected to the <b>HDMI1 port</b> on the side
        of <i>both</i> TVs.
    </>
)
const inputTraining = (
    <>
        Ensure the TV <b>input</b> is set to <b>HDMI1</b> for <i>both</i> TVs.
    </>
)

export {
    hdmiTraditional,
    computerOnK2Training,
    computerOnMarcy,
    computerOnPikes,
    hdmiK2,
    inputK2,
    hdmi2,
    inputHdmi2,
    hdmiTraining,
    inputTraining
}
