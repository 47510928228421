import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

function TVNoSignalFAQ({ computerStep, hdmiStep, inputStep, hdmiLaptop, confRoomComp, matterhorn, screenBeam }) {
    return (
        <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    No signal on TV (black screen)
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary= {confRoomComp} />
                    </ListItem>
                    {screenBeam ? <></> :
                        matterhorn ? <></> : <>
                        <ListItem>
                            <ListItemIcon>
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    hdmiStep ? (
                                        hdmiStep
                                    ) : (
                                        <>
                                            Ensure the HDMI cable is connected to
                                            the <b>HDMI1 port</b> on the side of the
                                            TV.
                                        </>
                                    )
                                }
                            />
                        </ListItem>
                        </>
                     }
                     {screenBeam ? <></> : <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                inputStep ? (
                                    inputStep
                                ) : (
                                    <>
                                        Ensure the TV <b>input</b> is set to{" "}
                                        <b>HDMI1</b>.
                                    </>
                                )
                            }
                        />
                    </ListItem>}
                    {computerStep ? (
                        <ListItem>
                            <ListItemIcon>
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={computerStep} />
                        </ListItem>
                    ) : (
                        <></>
                    )}
                    {hdmiLaptop ? (
                        <ListItem>
                            <ListItemIcon>
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={hdmiLaptop} />
                        </ListItem>
                    ) : (
                        <></>
                    )}
                    
                    
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVNoSignalFAQ
