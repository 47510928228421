import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import connectToAWirelessDisplay from "../../resources/images/connectToAWirelessDisplay.png"
import searchBarCast from "../../resources/images/searchBarCast.png"
import screenBeamSearch from "../../resources/images/screenBeamSearch.png"

function TVWirelessBuffer({ connectName, screenBeam, noWifi }) {
    return (
        <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    {screenBeam ? 'Unable to cast to TV with ScreenBeam' : 'Conference room computer is slow or cannot be cast to'}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary= { screenBeam ? 'Ensure the TV is on.' : "Ensure the TV and the conference room computer are both on." } />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={screenBeam ? "Restart your laptop." : "Restart the conference room computer, log in using the Training Room credentials and wait for connect to open. If it doesn't, click on the blue icon on the bottom of the screen to open it."} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                            primary= {
                                <>
                                    On your personal laptop, ensure that you are connected to PGISecure or 
                                    {screenBeam ? " the meeting room's Wi-Fi with the information on the TV" : noWifi ? ' PGIGuest. For the PGIGuest password, seek help from Precipart personnel' : " the meeting room's Wi-Fi"}.
                                    
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                            primary= {
                                <>
                                    { screenBeam ? 
                                    <>
                                    On your personal laptop, navigate to the <b>Search Bar</b> and type "ScreenBeam." Find the
                                        application and click it. If you do not have it, google "screenbeam download" and download it from their official website. </> : 
                                    <>
                                        On your personal laptop, hit ⊞ Win + k and choose "<u>{connectName}</u>" 
                                        to cast your computer content. Alternatively, 
                                        you can follow the remaining steps.
                                    </>
                                    }
                                    
                                </> 
                            }
                        />
                    </ListItem>
                    {screenBeam ? 
                        <ListItem>
                            <ListItemIcon></ListItemIcon>
                            <img
                                src={screenBeamSearch}
                                style={{ width: "81%", height: "85%" }}
                                alt="Screen Beam search up"
                            />
                        </ListItem> :
                        <>
                            <ListItem>
                                <ListItemIcon>
                                    <SubdirectoryArrowRightIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText 
                                    primary= {
                                        <>
                                            On your personal laptop, navigate to the <b>Search Bar</b> and type "cast." Find the {" "}
                                            <b>Connect to a wireless display</b> option and click it.
                                        </> 
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon></ListItemIcon>
                                <img
                                    src={searchBarCast}
                                    style={{ width: "81%", height: "85%" }}
                                    alt="Typing cast in the search bar"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <SubdirectoryArrowRightIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <>
                                            Scroll down and find the option <b>Connect to a wireless display</b>.
                                        </>
                                    }
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon></ListItemIcon>
                                <img
                                    src={connectToAWirelessDisplay}
                                    style={{ width: "81%", height: "85%" }}
                                    alt="Scroll down and find the connect to a wireless display option"
                                />
                            </ListItem>
                        </>
                    
                    }
                    
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    {screenBeam ? "Select the method you would like to cast with." : ""} Then, choose 
                                    "<u>{connectName}</u>" {screenBeam ? "and enter the PIN displayed on the TV when prompted on your laptop ": ""} 
                                    to cast the content on your computer.
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVWirelessBuffer
