import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import screenBeamSearch from "../../resources/images/screenBeamSearch.png"
import cameraSource from "../../resources/images/cameraSource.png"
import audioSource from "../../resources/images/audioSource.png"
import k2AudioSource from "../../resources/images/k2AudioSource.png"
import everestAudioSource from "../../resources/images/everestAudioSource.png"

function TVLoginEverestFAQ({ connectName, K2, everest }) {
    return (
        <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    Wireless casting with laptop, speakers and camera
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="On your personal laptop, ensure that you are connected to PGISecure or the meeting room's Wi-Fi. The room's Wi-Fi information is on the TV." />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    On your personal laptop, navigate to the <b>Search Bar</b> and type "ScreenBeam." Find the
                                    application and click it. If you do not have it, google "ScreenBeam download" and download it from their official website.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={screenBeamSearch}
                            style={{ width: "81%", height: "85%" }}
                            alt="Screen Beam search up"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={
                                <>
                                    Select <b>Project and Conference</b>. Choose "<u>{connectName}</u>" to cast your 
                                    computer content and enter the PIN displayed on the TV when prompted on your laptop.
                                </>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={
                                <>
                                    If you want to use the camera and speakers in a Teams meeting, navigate to the arrow next to both icons and
                                    change your input devices to the ones in the room. 
                                </>
                            } />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        {K2 ? 
                            <img
                                src={k2AudioSource}
                                style={{ width: "34.2%", height: "34.2%" }}
                                alt="Screen Beam search up"
                            /> 
                            : everest ? 
                            <img
                                src={everestAudioSource}
                                style={{ width: "36%", height: "36%" }}
                                alt="Screen Beam search up"
                                />  
                            :
                            <img
                                src={audioSource}
                                style={{ width: "41%", height: "42%" }}
                                alt="Screen Beam search up"
                            />
                        }
                        
                        <img
                            src={cameraSource}
                            style={{ width: "48.7%", height: "48.7%" }}
                            alt="Screen Beam search up"
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVLoginEverestFAQ
