import React from "react"
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight"

import connectToAWirelessDisplay from "../../resources/images/connectToAWirelessDisplay.png"
import searchBarCast from "../../resources/images/searchBarCast.png"

function TVWirelessCastFAQ({ connectName, PGIGuest, screenBeam }) {

    return (
        <Accordion disableGutters>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography sx={{ fontWeight: "1000" }}>
                    Wireless casting with laptop
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <List>
                    {
                    screenBeam ? <></> : 
                    <>
                        <ListItem>
                            <ListItemIcon>
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Ensure the TV is turned on." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SubdirectoryArrowRightIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={
                                    <>
                                        Ensure the HDMI cable from the conference room computer is connected to the <b>HDMI 2 port</b> on the side of the TV
                                    </> 
                                }/>
                        </ListItem>
                    </>
                    
                    }
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                            primary= {
                                <>
                                    On your personal laptop, ensure that you are connected to PGISecure {" "}
                                    {PGIGuest ? ' or PGIGuest. For the PGIGuest password, seek help from Precipart personnel': "or the meeting room's Wi-Fi. The room's Wi-Fi information is on the TV" }.
                                </> 
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                            primary= {
                                <>
                                    On your personal laptop, hit ⊞ Win + k and choose "<u>{connectName}</u>" to cast your content
                                    {screenBeam ? '. Enter the PIN displayed on the TV when prompted on your laptop' : "" }. 
                                    Alternatively, you can follow the remaining steps.
                                </> 
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText 
                            primary= {
                                <>
                                    On your personal laptop, navigate to the <b>Search Bar</b> and type "connect to a wireless display." 
                                    Find the {" "} <b>Connect to a wireless display</b> option and click it.
                                </> 
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={searchBarCast}
                            style={{ width: "81%", height: "85%" }}
                            alt="Type cast into the search bar"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Scroll down and find the option <b>Connect to a wireless display</b>.
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon></ListItemIcon>
                        <img
                            src={connectToAWirelessDisplay}
                            style={{ width: "81%", height: "85%" }}
                            alt="Scroll down to find the connect to a wireless display option"
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubdirectoryArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    Select the <b>Connect</b> option. Then,
                                    choose "<u>{connectName}</u>" 
                                    {screenBeam ? ' and enter then PIN displayed on the TV when prompted on your laptop' : "" }.
                                </>
                            }
                        />
                    </ListItem>
                </List>
            </AccordionDetails>
        </Accordion>
    )
}

export default TVWirelessCastFAQ
